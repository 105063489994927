import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Router, Routes } from 'react-router-dom';


import React, { Fragment } from 'react';
import AlertContextProvider from './context/alert_context';
import AuthContextProvider from './context/auth_context';
import LoginView from './views/login_view';
import ClientNavigator from './navigator/client_navigator';
import AlertNotification from './components/alert-notification';
import RegisterView from './views/register_view';
import RecoverPassView from './views/recover_pass_view';
import ResetPassView from './views/reset_pass_view';
import { IoLogoWhatsapp } from "react-icons/io";

function App() {

  return (

    <BrowserRouter>

      <AlertContextProvider>

        <AuthContextProvider>

          <AlertNotification></AlertNotification>

          <Routes>
            <Route path="/" element={<LoginView></LoginView>} />
            <Route path="/register" element={<RegisterView></RegisterView>} />
            <Route path="/recoverpass" element={<RecoverPassView></RecoverPassView>} />
            <Route path="/reset-password" element={<ResetPassView></ResetPassView>} />
            {/* <Route path="/registro" element={<RegisterView></RegisterView>}/>
                  <Route path="/politicas" element={<PoliticasView></PoliticasView>}/> */}



            <Route exact path="/user/*" element={<ClientNavigator></ClientNavigator>} />

            {/* 
                  <Route path="/about" element={ <div>about</div>  } />        */}

          </Routes>



        </AuthContextProvider>
      </AlertContextProvider>
      <div>
        <IoLogoWhatsapp size={100} color='#25D366' className='bv-icon bv-icon--big cursor-pointer ws-icon' id='ws' onClick={() => {
          window.open(`https://api.whatsapp.com/send/?phone=5218112858641&text=Hola%21%2C+quisiera+saber+m%C3%A1s+sobre+MyDiet.+Gracias.&type=phone_number&app_absent=0`, '_blank')
        }}></IoLogoWhatsapp>
      </div>
    </BrowserRouter>

  )
};


function PrivateRoute({ children }) {
  // const { user } = useContext(AuthContext);
  const user = 0;
  return user ? children : <Navigate to="/" />;
}


export default App;
