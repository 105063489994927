import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { Await, useLocation, useNavigate } from 'react-router-dom';
import { BiArrowBack, BiRefresh } from "react-icons/bi";
import { AiFillCloseCircle, AiOutlineTag, AiOutlineTags } from 'react-icons/ai';
import { IoArrowBackCircle } from "react-icons/io5";
import { Service } from '../services/api';
import { YYYYMMDD, date } from '../utils/utils';
import { AlertContext } from '../context/alert_context';
import Renderif from '../components/renderif';
import { AuthContext } from '../context/auth_context';
import { MdOutlineSwapHorizontalCircle } from "react-icons/md";
import Loader from '../components/loader';
import { FaHeart, FaExchangeAlt } from "react-icons/fa";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { IoReload } from "react-icons/io5";

export default function ComidasView() {

  const location = useLocation()
  const { user, logout } = useContext(AuthContext)

  let _day = location.state?.day_active

  _day = _day !== undefined ? _day : new Date()

  const navigate = useNavigate();
  const { notify } = useContext(AlertContext)
  const [state, setState] = useState({
    day_active: _day,
    recipe_active: 0,
    show_recipe: false,
    allRecipes: [],
    plan_comidas: [],
    recipes: null,
    allow_save: false,
    loading: true,
    menu_options: false,
    category_aliments: [],
    show_swap: false,
    show_swap_items: false,
    aliments: [],
    show_valoracion: false,
    show_swap_recipe: false,
    category_selected: null,
    reicpe_swap_time: null,
    valoracion_comment: '',
    history: [],
    loading_history: false,
    saucer_number: null,
    type_food: null,
  })

  useEffect(() => {
    // init()
    return () => { }
  }, [])

  useEffect(() => {




    const init = async () => {

      setState({ ...state, loading: true })


      let recipes = await Service('GET', 'recipes');
      if (recipes.status === 401) {
        logout();
        navigate('/')
      }
      let _plan_comidas = await Service('GET', `patients/${user?.id}/meal-plans`);
      let __aliments = await Service('GET', `aliments`);
      state.aliments = __aliments.data
      if (_plan_comidas.status === 401) {
        logout();
        navigate('/')
      }
      let _aliments = [];
      recipes.data.forEach((element) => element.ingredients.forEach((alimento) => {
        let _categoria = _aliments.find((find) => find.Categoria === alimento.aliment.grupo + alimento.aliment.subGrupo);
        if (!_categoria) {
          _aliments.push({ Categoria: alimento.aliment.grupo + alimento.aliment.subGrupo, Almentos: [alimento.aliment.alimentos] })
        }
        else {
          // _categoria.Almentos.push(alimento.aliment.alimentos)
          if (!_categoria.Almentos.find((al) => al === alimento.aliment.alimentos)) {
            _categoria.Almentos.push(alimento.aliment.alimentos)
          }
        }
      }
      )
        // 
      );

      console.log(_aliments)
      // _aliments.forEach((categoria) => categoria.Almentos.forEach((alimentos) => console.log(alimentos)));
      state.category_aliments = _aliments;
      state.allRecipes = recipes.data;
      state.plan_comidas = _plan_comidas.data
      console.log(state.allRecipes);
      // state.day_active = _day


      let _recipes = {
        breakfast: null
      };
      state.plan_comidas.map((item) => {


        if (YYYYMMDD(item.day) === YYYYMMDD(state.day_active.toISOString())) {
          _recipes = item;
        }
      })

      let random;

      if (_recipes.breakfast === null) {

        _recipes.day = state.day_active.toISOString()

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno").length)
        _recipes.breakfast = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida").length)
        _recipes.meal = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena").length)
        _recipes.dinner = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0).length)
        _recipes.snack_morning = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0)[random]
        console.log("snack_morning->", state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0)[random]);

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0).length)
        _recipes.snack_afternoon = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0)[random]
        console.log(state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0)[random]);

        _recipes.id = 0
        _recipes.userId = 6
        state.allow_save = true

      }

      console.log(_recipes);
      state.recipes = _recipes;
      state.recipe_active = _recipes?.breakfast
      // state.show_swap = false;
      state.loading = false

      setState({ ...state })
    }
    init();



    return () => { }

  }, [state.day_active])

  useEffect(() => {
    const init = async () => {
      setState({ ...state, loading_history: true })

      if (state.recipe_active !== 0 && state.recipe_active !== null) {
        let history = await Service('GET', `recipes-history/${state.recipe_active.id}`);
        console.log(history)
        state.history = history.data
        setState({ ...state, loading_history: false })
      }
    }
    init()
    return () => { }

  }, [state.recipe_active])





  const _init = async () => {
    // let _recipes = await Service('GET','recipes');
    // let _plan_comidas = await Service('GET','patients/6/meal-plans');


    // state.allRecipes = _recipes.data;
    // state.plan_comidas = _plan_comidas.data
    // state.day_active = _day

    // setState({...state, allRecipes:_recipes.data, plan_comidas:_plan_comidas.data, day_active:_day });


  }

  const save_changes_modal = async () => {
    notify({ title: "¡Ingredientes!", message: "Cambios guardados exitosamente!", type: 'success' })
  }


  const onSave = async () => {
    setState({ ...state, loading: true })
    let body = {
      day: YYYYMMDD(state.day_active.toISOString()),
      breakfast: state.recipes.breakfast.id,
      meal: state.recipes.meal.id,
      dinner: state.recipes.dinner.id,
      snack_morning: state.recipes.snack_morning.id,
      snack_afternoon: state.recipes.snack_afternoon.id
    }

    if (state.recipes.id === 0) {

      let save = await Service('POST', `patients/${user.id}/meal-plans`, body)
      if (save.status === 201) {
        notify({ title: "Listo!", message: "Se ha guardado este plan de comidas.", type: 'success' })
      } else if (save.status === 401) {
        logout();
        navigate('/')
      } else {
        notify({ title: "Error!", message: "No ha sido posible guardar ese plan de comidas.", type: 'error' })
      }

    } else {

      let update = await Service('PUT', `patients/${user.id}/meal-plans/${state.recipes.id}`, body)
      console.log(update)
      if (update.status === 200) {
        notify({ title: "Listo!", message: "Se ha guardado este plan de comidas.", type: 'success' })
      } else if (update.status === 401) {
        logout();
        navigate('/')
      } else {
        notify({ title: "Error!", message: "No ha sido posible guardar ese plan de comidas.", type: 'success' })
      }
    }
    setState({ ...state, loading: false })
  }



  function rand(max) {
    return Math.floor(Math.random() * max);
  }


  const swap_recipe = (time) => {
    state.allow_save = true
    let random;
    switch (time) {
      case 0:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno").length)
        console.log(state.recipes.breakfast)
        state.recipes.breakfast = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Desayuno")[random]

        setState({ ...state })
        return
      case 1:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida").length)
        state.recipes.meal = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Comida")[random]
        setState({ ...state })
        return
      case 2:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena").length)
        state.recipes.dinner = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime[0] === "Cena")[random]
        setState({ ...state })

        return

      case 3:


        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0).length)
        state.recipes.snack_morning = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0)[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Mañana").length > 0)[random]
        setState({ ...state })

        return

      case 4:

        random = rand(state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0).length)
        state.recipes.snack_afternoon = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0)[random]
        state.recipe_active = state.allRecipes.filter((desayuno) => desayuno.mealTime.filter((snack) => snack === "Colación de la Tarde").length > 0)[random]
        setState({ ...state })

        return

      default:
        break;
    }
  }

  const filterAlimetnsType = (aliment) => {

    let _alimentsFiltered = state.aliments.filter((item) => {
      if (item.grupo === aliment.aliment.grupo && item.subGrupo === aliment.aliment.subGrupo) {
        return true
      }
      return false
    })

    return (_alimentsFiltered)


  }

  const onValorarRecipe = async (valoracion) => {
    setState({ ...state, loading: true })
    let body = {
      score: valoracion,
      comments: state.valoracion_comment
    }
    let _valorar = await Service('POST', `recipes/${state.recipe_active.id}/comments`, body)
    console.log(_valorar)
    if (_valorar.status === 201) {
      notify({ title: "Listo!", message: "¡Valoracion enviada!", type: 'success' })
      setState({ ...state, show_valoracion: false, loading: false, valoracion_comment: '' })
    } else {
      notify({ title: "Valoración!", message: "No ha sido posible valorar la receta.", type: 'error' })
      setState({ ...state, show_valoracion: false, loading: false, valoracion_comment: '' })
    }
  }

  const onSwapAliment = async (aliment, newAliment, recipe) => {
    setState({ ...state, loading: true })
    let body = {
      alimentId: aliment,
      alimentNewId: newAliment
    }
    let _swap = await Service('POST', `recipes-history/${recipe}`, body)
    console.log(_swap)

    if (_swap.status === 201) {
      notify({ title: "¡Ingredientes!", message: "Ingrediente cambiado exitosamente", type: 'success' })

    } else {
      notify({ title: "¡Ingredientes!", message: "No ha sido posible cambiar este ingrediente.", type: 'error' })

    }

    setState({ ...state, show_swap_items: null, show_swap: false, loading: false })
  }



  return (
    <div className='container-fluid d-flex h-100'>
      <Renderif isTrue={state.loading}>
        <Loader></Loader>
      </Renderif>
      <Renderif isTrue={state.show_valoracion}>
        <div className='overlay d-flex justify-content-center align-items-center' onClick={(evt) => {
          setState({ ...state, show_valoracion: false })
        }}>
          <div className='bv-modal shadow' onClick={(evt) => { evt.stopPropagation() }}>

            <div className='d-flex flex-column align-items-center justify-content-center py-3'>
              <div className='f-28 text-center text-green bold'>¿Qué te pareció <br></br> preparar esta comida?</div>
              <div className='f-16 text-gray my-3'>¡Queremos conocer tu experiencia!</div>

              <div className='d-sm-flex w-100 p-4'>
                <div className='bv-cta bv-cta--success me-2 my-2 w-sm-25 w-100' onClick={() => {
                  onValorarRecipe('PODRIA_MEJORAR')
                  //  setState({...state, show_valoracion:false})
                }}>
                  <div>Puede mejorar</div>
                </div>
                <div className='bv-cta bv-cta--success me-2 my-2 w-sm-25 w-100' onClick={() => {
                  onValorarRecipe('ESTUVO_BIEN')
                }}>
                  <div>Estuvo bien</div>
                </div>
                <div className='bv-cta bv-cta--success w-sm-25 my-2 w-100' onClick={() => {
                  onValorarRecipe('FUE_GENIAL')
                }}>
                  <div>¡Fué Genial!</div>
                </div>
              </div>


              <div className='d-flex flex-column mt-3 w-75'>
                <textarea value={state.valoracion_comment} onChange={(evt) => {
                  setState({ ...state, valoracion_comment: evt.currentTarget.value })
                }} className='input--light mb-3 pt-1 ' rows={6} placeholder='Describe tu experiencia.'></textarea>
              </div>
            </div>

          </div>

        </div>
      </Renderif>

      <Renderif isTrue={state.show_swap_recipe}>
        <div className='overlay d-flex justify-content-center align-items-center' onClick={(evt) => {
          setState({ ...state, show_valoracion: false })
        }}>
          <div className='bv-modal hide-scroll shadow' onClick={(evt) => { evt.stopPropagation() }}>

            <div className='d-flex flex-column  pt-3'>

              <div className=' px-3 d-flex align-items-center'>
                <div className='f-24  text-green bold'>Cambio de receta</div>
                <div className='col'></div>
                <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                  setState({ ...state, show_swap_recipe: false })

                }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
              </div>
              <div className='f-16 text-gray px-3'>Plan Alimenticio: </div>
              <div className='f-16 text-gray mt-3 px-3'>Selecciona la receta por la que desees.</div>

              <div className='px-3'>
                <div className='bv-tag bv-tag--gray'>
                  <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                  <div className='text-gray pe-2'>{state.category_selected}</div>
                </div>
                <div className='bv-tag bv-tag--gray'>
                  <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                  {/* <div className='text-gray pe-2'>{state.recipe_active?.mealTime[0]}</div> */}
                </div>
              </div>

              {
                state.allRecipes.filter((item) => {
                  if (item.mealCategories.includes(state.category_selected) && item.mealTime.includes(state.recipe_active.mealTime[0])) {
                    return true
                  }
                  if (item.mealSubCategories.includes(state.category_selected) && item.mealTime.includes(state.recipe_active.mealTime[0])) {
                    return true
                  }

                  return false
                }).map((recipe, i) => {
                  console.log(state.category_selected)
                  console.log(recipe)
                  return (
                    <div key={i} className='tip-card tip-card--horizontal cursor-pointer m-2' onClick={() => {
                      // setState({ ...state, recipe_active: state.recipes?.breakfast })

                      switch (state.recipe_active.mealTime[0]) {
                        case 'Desayuno':
                          state.recipes.breakfast = recipe;
                          state.recipe_active = recipe;
                          setState({ ...state, show_swap_recipe: false })
                          break;
                        case 'Comida':
                          state.recipes.meal = recipe;
                          state.recipe_active = recipe;
                          setState({ ...state, show_swap_recipe: false })
                          break;
                        case 'Cena':
                          state.recipes.dinner = recipe;
                          state.recipe_active = recipe;
                          setState({ ...state, show_swap_recipe: false })
                          break;
                        // case 'Cena':
                        //   state.recipes.dinner = recipe;
                        //   state.recipe_active = recipe;
                        //   setState({ ...state, show_swap_recipe: false })
                        //   break;
                        // case 'Cena':
                        //   state.recipes.dinner = recipe;
                        //   state.recipe_active = recipe;
                        //   setState({ ...state, show_swap_recipe: false })
                        //   break;
                        default:
                          break;
                      }

                      console.log(recipe)
                    }}>
                      <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${recipe.image?.name})` }}></div>



                      <div className='col ps-2'>
                        <div className='f-16 text-green bolder p-1'>{recipe?.name}</div>
                        <div className='f-14 text-gray p-1 text-ellipsis-2'>{recipe?.preparation}</div>
                      </div>
                    </div>
                  )
                })
              }




            </div>

          </div>

        </div>
      </Renderif>

      <div>
        <SideMenu page={'comidas'}></SideMenu>
      </div>

      <div className='col h-100 overflow-auto hide-scroll'>

        <div className='container-fluid bg-white sticky-top mobile-d-none'>
          <img className='m-3' width={120} src='../assets/images/logo.png'></img>
        </div>

        <div className='container-fluid pb-5 h-100'>

          <div className='container-fluid   p-sm-3 h-100'>
            <div className='h-100 overflow-auto-mobile'>


              <Renderif isTrue={state.show_recipe === false}>
                <div className='col-10 d-flex pe-2 container-xl mx-auto my-3'>
                  <div>
                    <div className='f-28 text-gray bold line-0'>Comidas</div>
                    <div className='f-26 text-gray line-0'>Plan Alimenticio</div>
                  </div>
                  <div className='col'>

                  </div>
                  <div className='pe-3 mobile-d-none'>
                    <div className={`bv-cta bv-cta--${state.allow_save === true ? 'success' : 'disabled'}`} onClick={() => {
                      onSave()
                    }}>
                      Guardar

                    </div>
                  </div>
                  <div className='pe-3'>


                    <input type='date' value={state.day_active?.toISOString().split('T')[0]} onChange={(evt) => {

                      setState({ ...state, day_active: new Date(evt.currentTarget.value) })
                      console.log(evt.currentTarget.value)

                    }} className='input--light'></input>
                    <div className='text-green f-14 text-end text-decoration-underline cursor-pointer' onClick={() => {
                      navigate('/user/comida/semana')
                    }}>
                      Ver Semana
                    </div>
                  </div>
                </div>
                <div className='ps-2 d-sm-none'>
                  <div className={`bv-cta bv-cta--${state.allow_save === true ? 'success' : 'disabled'}`} onClick={() => {
                    onSave()
                  }}>
                    Guardar

                  </div>
                </div>


                <div className='col-sm-10 d-flex pe-2 container-xl mx-auto mobile-d-none'>

                  <div className='col m-2 ' >
                    <div className='d-flex align-items-center'>
                      <div className='f-22 text-gray bold'>Desayuno</div>
                      <div className='col'></div>
                      <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                        swap_recipe(0)
                      }}>
                        <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                      </div>
                    </div>

                    <div className='tip-card h-75 d-flex flex-column' onClick={() => {
                      // setState({ ...state, recipe_active: state.recipes?.breakfast })
                    }}>

                      <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.breakfast?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.breakfast?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                      {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                      <div className='f-16 text-green bolder p-1'>{state.recipes?.breakfast?.name}</div>
                      <ul className='f-14 text-gray pt-1'>
                        {
                          state.recipes?.breakfast?.ingredients.map((item, i) => {
                            return (
                              <li>{item.aliment.alimentos}</li>
                            )
                          })
                        }
                      </ul>
                      <div className='f-14 text-gray p-1 text-ellipsis-punter'>{state.recipes?.breakfast?.preparation}</div>
                      <div className='col'></div>
                      <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.breakfast, show_recipe: true, saucer_number: 0 })
                      }}>
                        Ver mas
                      </div>
                    </div>
                  </div>

                  <div className='col m-2'>
                    <div className='d-flex align-items-center'>
                      <div className='f-22 text-gray bold'>Colacion 1</div>
                      <div className='col'></div>
                      <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                        swap_recipe(3)
                      }}>
                        <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                      </div>
                    </div>

                    <div className='tip-card h-75 d-flex flex-column' onClick={() => {
                      // setState({ ...state, recipe_active: state.recipes?.breakfast })
                    }}>

                      <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.snack_morning?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.snack_morning?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                      {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                      <div className='f-16 text-green bolder p-1'>{state.recipes?.snack_morning?.name}</div>
                      <ul className='f-14 text-gray pt-1'>
                        {
                          state.recipes?.snack_morning?.ingredients.map((item, i) => {
                            return (
                              <li>{item.aliment.alimentos}</li>
                            )
                          })
                        }
                      </ul>
                      <div className='f-14 text-gray p-1 text-ellipsis-punter'>{state.recipes?.snack_morning?.preparation}</div>
                      <div className='col'></div>
                      <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.snack_morning, show_recipe: true, saucer_number: 3 })
                      }}>
                        Ver mas
                      </div>
                    </div>
                  </div>

                  <div className='col m-2'>
                    <div className='d-flex align-items-center'>
                      <div className='f-22 text-gray bold'>Comida</div>
                      <div className='col'></div>
                      <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                        swap_recipe(1)
                      }}>
                        <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                      </div>
                    </div>

                    <div className='tip-card h-75 d-flex flex-column' onClick={() => {
                      // console.log(state.recipes?.mel)
                      // setState({ ...state, recipe_active: state.recipes?.meal })
                    }}>
                      {/* <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name})` }}></div> */}
                      <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>

                      <div className='f-16 text-green bolder p-1'>{state.recipes?.meal?.name}</div>
                      <ul className='f-14 text-gray pt-1'>
                        {
                          state.recipes?.meal?.ingredients.map((item, i) => {
                            return (
                              <li>{item.aliment.alimentos}</li>
                            )
                          })
                        }
                      </ul>
                      <div className='f-14 text-gray p-1 text-ellipsis-punter'>{state.recipes?.meal?.preparation}</div>
                      <div className='col'></div>
                      <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.meal, show_recipe: true, saucer_number: 1 })
                      }}>
                        Ver mas
                      </div>
                    </div>
                  </div>

                  <div className='col m-2'>
                    <div className='d-flex align-items-center'>
                      <div className='f-22 text-gray bold'>Colacion 2</div>
                      <div className='col'></div>
                      <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                        swap_recipe(4)
                      }}>
                        <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                      </div>
                    </div>

                    <div className='tip-card h-75 d-flex flex-column' onClick={() => {
                      // setState({ ...state, recipe_active: state.recipes?.breakfast })
                    }}>

                      <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.snack_afternoon?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.snack_afternoon?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                      {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                      <div className='f-16 text-green bolder p-1'>{state.recipes?.snack_afternoon?.name}</div>
                      <ul className='f-14 text-gray pt-1'>
                        {
                          state.recipes?.snack_afternoon?.ingredients.map((item, i) => {
                            return (
                              <li>{item.aliment.alimentos}</li>
                            )
                          })
                        }
                      </ul>
                      <div className='f-14 text-gray p-1 text-ellipsis-punter'>{state.recipes?.snack_afternoon?.preparation}</div>
                      <div className='col'></div>
                      <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.snack_afternoon, show_recipe: true, saucer_number: 4 })
                      }}>
                        Ver mas
                      </div>
                    </div>
                  </div>

                  <div className='col m-2'>

                    <div className='d-flex align-items-center'>
                      <div className='f-22 text-gray bold'>Cena</div>
                      <div className='col'></div>
                      <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                        swap_recipe(2)
                      }}>
                        <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                      </div>
                    </div>

                    <div className='tip-card h-75 d-flex flex-column' onClick={() => {
                      // setState({ ...state, recipe_active: state.recipes?.dinner })
                    }}>
                      <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.dinner?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.dinner?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>

                      <div className='f-16 text-green bolder p-1'>{state.recipes?.dinner?.name}</div>
                      <ul className='f-14 text-gray pt-1'>
                        {
                          state.recipes?.dinner?.ingredients.map((item, i) => {
                            return (
                              <li>{item.aliment.alimentos}</li>
                            )
                          })
                        }
                      </ul>
                      <div className='f-14 text-gray p-1 text-ellipsis-punter'>{state.recipes?.dinner?.preparation}</div>
                      <div className='col'></div>
                      <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.dinner, show_recipe: true, saucer_number: 2 })
                      }}>
                        Ver mas
                      </div>
                    </div>

                  </div>

                </div>
                <div className='col-12 d-flex pe-2  mx-auto d-sm-none '>

                  <div className='col m-2 mb-4' >
                    <div>
                      <div className='d-flex align-items-center'>
                        <div className='f-22 text-gray bold'>Desayuno</div>
                        <div className='col'></div>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                          swap_recipe(0)
                        }}>
                          <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                        </div>
                      </div>

                      <div className='h-75 d-flex flex-column' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.breakfast, show_recipe: true })
                      }}>

                        <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.breakfast?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.breakfast?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                        {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                        <div className='f-16 text-green bolder p-1 height-name-dinner'>{state.recipes?.breakfast?.name}</div>
                      </div>
                    </div>
                    <div>
                      <div className='d-flex align-items-center'>
                        <div className='f-22 text-gray bold'>Comida</div>
                        <div className='col'></div>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                          swap_recipe(1)
                        }}>
                          <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                        </div>
                      </div>

                      <div className='h-75 d-flex flex-column' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.meal, show_recipe: true })
                      }}>
                        {/* <div className='tip-full-image' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name})` }}></div> */}
                        <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.meal?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>

                        <div className='f-16 text-green bolder p-1 height-name-dinner'>{state.recipes?.meal?.name}</div>

                      </div>
                    </div>
                    <div>
                      <div className='d-flex align-items-center'>
                        <div className='f-22 text-gray bold'>Cena</div>
                        <div className='col'></div>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                          swap_recipe(2)
                        }}>
                          <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                        </div>
                      </div>

                      <div className='h-75 d-flex flex-column' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.dinner, show_recipe: true })
                      }}>
                        <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.dinner?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.dinner?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>

                        <div className='f-16 text-green bolder p-1 height-name-dinner'>{state.recipes?.dinner?.name}</div>

                      </div>
                    </div>
                  </div>

                  <div className='col m-2'>
                    <div>
                      <div className='d-flex align-items-center'>
                        <div className='f-22 text-gray bold'>Colacion 1</div>
                        <div className='col'></div>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                          swap_recipe(3)
                        }}>
                          <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                        </div>
                      </div>

                      <div className='h-75 d-flex flex-column' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.snack_morning, show_recipe: true })
                      }}>

                        <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.snack_morning?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.snack_morning?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                        {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                        <div className='f-16 text-green bolder p-1 height-name-dinner'>{state.recipes?.snack_morning?.name}</div>
                      </div>
                    </div>

                    <div>
                      <div className='d-flex align-items-center'>
                        <div className='f-22 text-gray bold'>Colacion 2</div>
                        <div className='col'></div>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                          swap_recipe(4)
                        }}>
                          <BiRefresh size={30} color='#00bf6b'></BiRefresh>
                        </div>
                      </div>

                      <div className='h-75 d-flex flex-column' onClick={() => {
                        setState({ ...state, recipe_active: state.recipes?.snack_afternoon, show_recipe: true })
                      }}>

                        <div className='tip-full-image' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipes?.snack_afternoon?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipes?.snack_afternoon?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>
                        {/* <img className='w-100 cursor-pointer' src={`https://mydiet-assets-2023.s3.amazonaws.com/${state.recipes?.breakfast.image}`}></img> */}

                        <div className='f-16 text-green bolder p-1 height-name-dinner'>{state.recipes?.snack_afternoon?.name}</div>

                      </div>
                    </div>
                  </div>

                </div>

              </Renderif>

              <Renderif isTrue={state.show_recipe === true}>

                <div className='col-12 px-sm-3'>

                  <Renderif isTrue={state.loading_history}>
                    <div className='d-flex justify-content-center align-items-center mt-5 mt-5'>
                      <div className="spinner-border"></div>
                    </div>
                  </Renderif>

                  <Renderif isTrue={!state.loading_history}>
                    <div className='container-fluid d-flex'>
                      <div className='col-sm-1 mobile-d-none'>
                        <div className='m-2'>
                          <IoArrowBackCircle size={55} color='#00BF6B' className='cursor-pointer' onClick={() => {
                            setState({ ...state, show_recipe: false })
                          }} />
                        </div>
                      </div>
                      <div className='col-12 col-sm-10'>


                        <div className='d-flex mobile-d-none'>
                          <div>
                            <div className='f-28 text-gray bold line-0'>Comidas</div>
                            <div className='f-26 text-gray line-0'>Plan Alimenticio</div>
                          </div>
                          <div className='col'>

                          </div>
                          <div className='pe-3'>


                            <input type='date' value={state.day_active?.toISOString().split('T')[0]} onChange={(evt) => {

                              setState({ ...state, day_active: new Date(evt.currentTarget.value) })
                              console.log(evt.currentTarget.value)

                            }} className='input--light'></input>
                          </div>
                        </div>

                        <div className='d-sm-none tip-full-aux-full-mid-mobile d-flex flex-column ' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipe_active?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipe_active?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}>

                          <div>
                            <div className='m-2 mb-4'>
                              <IoArrowBackCircle size={55} color='#00BF6B' className='cursor-pointer' onClick={() => {
                                setState({ ...state, show_recipe: false })
                              }} />
                            </div>
                          </div>
                          <div className='col'></div>
                          <div className='d-flex mb-2'>
                            <div className='ms-4 '>
                              <div className='f-28 text-white bold line-0'>Comidas</div>
                              <div className='f-26 text-white line-0'>Plan Alimenticio</div>
                            </div>
                            <div className='col'></div>
                            <div className='d-flex-center me-3'>
                              <div className='cursor-pointer ' onClick={() => {
                                setState({ ...state, show_valoracion: true })
                              }}>
                                <div className='' >
                                  <FaHeart size={32} color={'red'} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='my-4 d-sm-flex p-3'>
                          <div className='col-12 col-sm-6'>
                            <div className='d-flex'>
                              <div className='f-26 text-gray bold line-0'>{state.recipe_active?.mealTime}</div>
                              <div className='col'></div>
                              <div className='d-flex mobile-d-none cursor-pointer' onClick={() => {
                                swap_recipe(state.saucer_number)
                              }}>
                                <div className='f-16 text-green line-0 mx-3 d-flex-center' >Cambiar</div>
                                <div className='d-flex-center'><div className='circle d-flex-center'><IoReload size={30} color={'white'} /></div></div>
                              </div>
                            </div>
                            <div>
                              <div className='f-26 text-green p-1'>{state.recipe_active?.name}</div>
                            </div>
                            <div className='tags'>
                              {state.recipe_active?.mealCategories?.map((item, i) => {
                                return (
                                  <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={() => {
                                    setState({ ...state, show_swap_recipe: true, category_selected: item })
                                  }}>
                                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                                    <div className='text-gray pe-2'>{item}</div>
                                  </div>
                                )
                              })}
                              {state.recipe_active?.mealSubCategories?.map((item, i) => {
                                return (
                                  <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={() => {
                                    setState({ ...state, show_swap_recipe: true, category_selected: item })
                                  }}>
                                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                                    <div className='text-gray pe-2'>{item}</div>
                                  </div>
                                )
                              })}
                              {state.recipe_active?.mealTime?.map((item, i) => {
                                return (
                                  <div key={i} className='bv-tag bv-tag--gray cursor-pointer' onClick={() => {
                                    setState({ ...state, show_swap_recipe: true, category_selected: item })
                                  }}>
                                    <div className='bv-icon'><AiOutlineTags></AiOutlineTags></div>
                                    <div className='text-gray pe-2'>{item}</div>
                                  </div>
                                )
                              })}


                            </div>
                            <div className='d-flex mobile-d-none'>
                              <div className='col'>

                              </div>
                              <div className='d-flex cursor-pointer' onClick={() => {
                                setState({ ...state, show_valoracion: true })
                              }}>
                                <div className='text-green f-16 mx-3'>
                                  <div>
                                    Marcar
                                  </div>
                                  <div>
                                    Consumido
                                  </div>
                                </div>
                                <div className='d-flex-center' >
                                  <FaHeart size={32} color={'red'} />
                                </div>
                              </div>
                            </div>
                            <div className='f-16 text-green bolder p-1'>Ingredientes</div>
                            <div>
                              <table className='table-ingredients'>
                                <tr className='text-green'>
                                  <th>Cambiar</th>
                                  <th>Ingrediente</th>
                                  <th>Cantidad</th>
                                </tr>

                                {state.recipe_active?.ingredients?.map((item, i) => {

                                  let _swaped_items = state.history.filter((_item) => _item.alimentId === item.aliment.id);
                                  let _newitem = null;
                                  if (_swaped_items.length > 0) {
                                    let _newingredient = _swaped_items[_swaped_items.length - 1]
                                    _newitem = state.aliments.find((aliment) => aliment.id === _newingredient.alimentNewId)
                                  }
                                  return (

                                    <tr className='position-relative' key={i} onClick={() => {

                                    }}>
                                      <td className='d-flex-center'>
                                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                                          setState({ ...state, show_swap: true, show_swap_items: i })

                                        }}>
                                          <LiaExchangeAltSolid size={20} color='#00bf6b' ></LiaExchangeAltSolid >
                                        </div>
                                      </td>

                                      <td>
                                        <Renderif isTrue={_newitem !== null}>
                                          <div className={`text-gray ingredient${i}`}>{_newitem?.alimentos}</div>
                                        </Renderif>
                                        <Renderif isTrue={_newitem === null}>
                                          <div className={`text-gray ingredient${i}`}> {item.aliment.alimentos}</div>
                                        </Renderif>
                                      </td>
                                      <td>
                                        <Renderif isTrue={_newitem !== null}>
                                          <div className={`text-gray ingredient${i}`}>{(_newitem?.cantidadSugeridaNum)} {_newitem?.unidad}.</div>
                                        </Renderif>
                                        <Renderif isTrue={_newitem === null}>
                                          <div className={`text-gray ingredient${i}`}>{(item.aliment.cantidadSugeridaNum * item.quantity)} {item.aliment.unidad}.</div>
                                        </Renderif>
                                      </td>
                                      <Renderif isTrue={state.show_swap_items === i}>
                                        <div className={`bv-list-option`}>
                                          <ul className='list-style-none p-2'>
                                            {filterAlimetnsType(item).map((ingre, j) => {


                                              return (
                                                <li key={j}>
                                                  <div className='cursor-pointer highlight-hover--light py-1' onClick={async () => {

                                                    console.log(item.recipeId)
                                                    console.log(ingre.id)


                                                    await onSwapAliment(item.aliment.id, ingre.id, item.recipeId)

                                                    item.aliment = ingre;
                                                    item.alimentId = ingre.id

                                                  }}>{ingre.alimentos}</div>
                                                </li>
                                              )
                                            })}
                                          </ul>
                                        </div>
                                      </Renderif>

                                    </tr>
                                  )
                                })}
                              </table>
                            </div>
                            <div className='my-4'>
                              <div className={`w-50 f-20 bv-cta bv-cta--success`} onClick={() => {
                                // swap_recipe(state.saucer_number)
                                save_changes_modal()
                              }}>
                                Guardar Cambios
                              </div>
                            </div>
                            <div className='f-16 text-green bolder p-1'>Preparación</div>
                            <div className='mt-3 text-gray mb-4'>

                              {state.recipe_active?.preparation}
                            </div>

                          </div>
                          <div className='col-12 col-sm-6 p-4'>

                            <div className='tip-full-aux-full-mid ' style={{ backgroundImage: ` url( ${state.allRecipes.find((item) => item.id === state.recipe_active?.id)?.image?.name !== undefined ? `https://mydiet-assets-2023.s3.amazonaws.com/${state.allRecipes.find((item) => item.id === state.recipe_active?.id)?.image?.name}` : '/assets/images/default_recipe.png'}` }}></div>

                          </div>
                        </div>
                      </div>
                      <div className='col-sm-1 mobile-d-none'>

                      </div>
                    </div>

                  </Renderif>


                </div>
              </Renderif>


            </div>
          </div>

        </div>



      </div>

    </div>
  )
}
