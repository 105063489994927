

import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';


import React, { Fragment, useContext, useEffect } from 'react';

import { AuthContext } from '../context/auth_context';
import HomeView from '../views/home_view';
import ComidasView from '../views/comidas_view_new';
import CitasView from '../views/citas_view';
import PerfilView from '../views/perfil_view';
import PlanDetail from '../views/plan_detail';
import TipDetail from '../views/tip_detail_view';
import ComidaDetail from '../views/comida_detail_view';
import ComidasSemanaView from '../views/comidas_semana_view';
import AgendarCitaView from '../views/agendar_cita_view';
import PreciosView from '../views/precios_view';
import NutriologiaView from '../views/nutriologia_view';
import PsicologiaView from '../views/psicologia_view';
import GinecologiaView from '../views/ginecologia_view';
import DetallePlanView from '../views/detalle_plan_view';
import TemasView from '../views/temas_view';
import TemaDetalleView from '../views/tema_detalle_view';



function ClientNavigator() {
  return (

    <PrivateRoute>
      <Routes>
        <Route path="/home" element={<HomeView></HomeView>} />
        <Route path="/comidas" element={<ComidasView></ComidasView>} />
        <Route path="/citas" element={<CitasView></CitasView>} />
        <Route path="/perfil" element={<PerfilView></PerfilView>} />
        <Route path="/plan" element={<PlanDetail></PlanDetail>} />
        <Route path="/tip" element={<TipDetail></TipDetail>} />
        <Route path="/comida" element={<ComidaDetail></ComidaDetail>} />
        <Route path="/comida/semana" element={<ComidasSemanaView></ComidasSemanaView>} />
        <Route path="/agendar/:especialidad" element={<AgendarCitaView></AgendarCitaView>} />
        <Route path="/precios" element={<PreciosView></PreciosView>} />
        <Route path="/nutriologia" element={<NutriologiaView></NutriologiaView>} />
        <Route path="/psicologia" element={<PsicologiaView></PsicologiaView>} />
        <Route path="/ginecologia" element={<GinecologiaView></GinecologiaView>} />
        <Route path="/detalleplan" element={<DetallePlanView></DetallePlanView>} />
        <Route path="/" element={<TemasView></TemasView>} />
        <Route path="/tema/detalle" element={<TemaDetalleView></TemaDetalleView>} />
      </Routes>
    </PrivateRoute>

  );
}



function PrivateRoute({ children }) {

  const { user, auth, loading } = useContext(AuthContext);


  return children;

  let token = localStorage.getItem('token');
  if (token !== null) {

    if (loading) {
      return <></>
    }
    if (user.roles?.includes('User') && !loading) {
      return children;
    }

  } else {
    return <Navigate to="/" />
  }



  // return user.roles?.includes('User') && !loading ? children : <Navigate to="/" />;
}


export default ClientNavigator;
