import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useLocation, useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { IoArrowBackCircle, IoArrowUpCircle, IoInformationCircleOutline, IoPeopleOutline } from "react-icons/io5";
import { IoIosArrowDropupCircle, IoIosArrowDropdownCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { PiSpeakerHighBold } from "react-icons/pi";
import { RxSize } from "react-icons/rx";
import axios from 'axios';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import Loader from '../components/loader';
import { AlertContext } from '../context/alert_context';
import { BsBook } from "react-icons/bs";
import { type } from '@testing-library/user-event/dist/type';
import useSound from 'use-sound';
import { AiFillCloseCircle } from 'react-icons/ai'

export default function TemaDetalleView() {
    const navigate = useNavigate();
    const { notify } = useContext(AlertContext)
    const location = useLocation();
    const _curso = location?.state?.curso;
    // console.log("curso->", _curso)

    // console.log(JSON.parse(_curso?.data))


    const { user, logout } = useContext(AuthContext)

    const [state, setState] = useState({
        userId: null,
        firstName: null,
        planes: [],
        plan: [],
        loading: true,
        success: false,
        btn_select: 1,
        semanas: [],
        mostrar_semana: 0,
        sub_tema_select_index: null,
        mostrar_dia: null,
        sub_tema_select: 1,
        tema_dia: null,
        curso: [],
        curso_progress: [],
        tips: {},
        tip_asset: null,
        full_screen: false,
        category_id: 0,
        is_responding: null,
        responding_comment_userName: null,
        responding_comment_userId: null,
        responding_commentId: null,
        commentaries: [],
        view_answers: null,
        sub_tema_modal: false,
        curso_id: null
    })

    useEffect(() => {


        init();

        return () => { }
    }, [])

    const init = async () => {
        // console.log("USER----->", user)
        const ACCESS_TOKEN = "rY46Ylrq96wuj2fPp90ok5Ku96C0LctV3j-furqSwCM";
        const SPACE_ID = "cyupqx8f0n3c";
        // setState({ ...state, loading: true })
        let _progress = [];
        let _commentaries = [];
        let _curso_id = null;
        let user_id;
        let firstName;
        if (user.id) {
            sessionStorage.setItem("id", user.id);
            state.userId = user.id;
            sessionStorage.setItem("firstName", user.firstName);
            state.firstName = user.firstName;
        } else {
            user_id = sessionStorage.getItem("id");
            state.userId = user_id;
            firstName = sessionStorage.getItem("firstName");
            state.firstName = firstName;
        }

        // console.log("USUARIO->",user.id)
        // console.log("CURSO->",_curso.id)
        // console.log("user_id->",user_id)

        let _course_progress = null;

        if (user.id) {
            _course_progress = await Service('GET', `course-progress/${_curso.id}/${user.id}`);
        } else {
            _course_progress = await Service('GET', `course-progress/${_curso.id}/${user_id}`);
        }
        console.log("_course_progress->", _course_progress)


        if (_course_progress.status === 200) {
            _progress = JSON.parse(_course_progress.data?.progress)
            _curso_id = _course_progress.data?.id

        } else if (_course_progress.response.status === 400) {
            console.log("No se encontró progreso")

            const _get_course = await Service('GET', `courses/${_curso.id}`);
            // console.log("_get_course->", _get_course)
            if (_get_course.status !== 200) {
                navigate('/user')
            }

            let _body = {
                user: {
                    id: user.id
                },
                course: {
                    id: _curso.id
                },
                progress: JSON.parse(_get_course.data?.data)
            }
            // console.log(_body.progress)
            _body.progress.forEach(element => {
                element.curso.modulos.forEach(curso => {
                    curso.temas.forEach(tema => {
                        tema.status = false;
                        tema.subtemas.forEach(subtema => {
                            subtema.status = false
                        });
                    });
                });
            });

            _progress = _body.progress;
            _body.progress = JSON.stringify(_body.progress);
            console.log(_body)

            let _new_course_progress = await Service('POST', `course-progress`, _body);

            console.log("_new_course_progress->", _new_course_progress)
            if (_new_course_progress.status === 201) {
                _curso_id = _new_course_progress.data?.id
            }
        }


        const { data } = await axios.get(
            `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/testing/entries`,
            {
                baseURL: "",
                params: {
                    content_type: 'tip',
                    access_token: ACCESS_TOKEN,
                },
            }

        );
        // console.log("curso", _curso.id)
        let get_commentaries = await Service('GET', `commentary`);

        if (get_commentaries.status === 200) {
            let parent_commentaries = get_commentaries.data.filter((commentary) => commentary.courseId === _curso.id && !commentary.parentId);
            let child_commentaries = get_commentaries.data.filter((commentary) => commentary.courseId === _curso.id && commentary.parentId);

            parent_commentaries.forEach(parent_commentarie => {
                parent_commentarie.answers = child_commentaries.filter((commentarie) => commentarie.parentId === parent_commentarie.id);
            });
            // console.log("comentarios PADRE", parent_commentaries)
            // console.log("comentarios HIJO", child_commentaries)

            _commentaries = parent_commentaries;
        }

        console.log("_progress->", _progress)
        state.curso_id = _curso_id;
        state.tips = data;
        state.curso = _progress;
        state.curso_progress = _progress;
        state.commentaries = _commentaries;
        state.loading = false;

        setState({ ...state })
        // setState({ ...state, tips: data, loading: true, curso: JSON.parse(_curso.data), curso_progress: _progress })
    }

    const askQuestion = async () => {
        const _question = document.getElementById("txtarea").value
        if (_question.length === 0) {
            notify({ title: "Error!", message: "Necesitas escribir una pregunta.", type: 'error' })
            return;
        }
        // console.log(_question)
        const body = {
            content: _question,
            courseId: _curso.id,
            parentId: 0
        }
        // console.log(body)
        let _question_post = await Service('POST', `commentary`, body);
        // console.log(_question_post)
        if (_question_post.status === 201) {
            const _body_comentary = {
                content: _question_post.data.content,
                courseId: _question_post.data.courseId,
                createdAt: _question_post.data.createdAt,
                id: _question_post.data.id,
                parentId: _question_post.data.parentId,
                userId: _question_post.data.userId,
                user: { firstName: state.firstName },
                likes: [],
                answers: [],
            }
            state.commentaries.push(_body_comentary);
            setState({ ...state })
            document.getElementById("txtarea").value = "";
        }
    }

    const update_curso = async () => {
        if (state.curso_id !== null) {
            // console.log("curso_id->",state.curso_id)
            // console.log("progreso-state.curso>", JSON.stringify(state.curso))
            let body = {
                progress: JSON.stringify(state.curso)
            }

            let _progress_response = await Service('PATCH', `course-progress/${state.curso_id}`, body);
            console.log(_progress_response)
        }
    }

    const responseQuestion = async () => {
        console.log(state.commentaries.find((commentary) => commentary.id === state.is_responding).answers.push())
        const _question = document.getElementById("txtarea_response").value
        if (_question.length === 0) {
            notify({ title: "Error!", message: "Necesitas escribir una respuesta.", type: 'error' })
            return;
        }
        // console.log(_question)
        const body = {
            content: _question,
            courseId: _curso.id,
            parentId: state.is_responding
        }
        console.log(body)
        let _question_post = await Service('POST', `commentary`, body);
        console.log(_question_post)
        if (_question_post.status === 201) {
            const _body_comentary = {
                content: _question_post.data.content,
                courseId: _question_post.data.courseId,
                createdAt: _question_post.data.createdAt,
                id: _question_post.data.id,
                parentId: _question_post.data.parentId,
                userId: _question_post.data.userId,
                user: { firstName: state.firstName },
                likes: [],
                answers: [],
            }
            state.commentaries.find((commentary) => commentary.id === state.is_responding).answers.push(_body_comentary);
            setState({ ...state })
            document.getElementById("txtarea_response").value = "";
        }
    }

    const addLike = async (id) => {
        let commentary = state.commentaries.find((comment) => comment.id === id);
        if (commentary) {
            let _like = await Service('POST', `commentary/${id}/like`);
            if (_like.status === 201) {
                commentary.likes.push(_like.data)
                setState({ ...state })
            }
        }
    }

    const addAsnwerLike = async (commentId, responseId) => {
        let commentary = state.commentaries.find((comment) => comment.id === commentId);
        if (commentary) {
            let answer = commentary.answers.find((answer) => answer.id === responseId);
            // console.log("answer->>",answer)
            if (answer) {
                let _like = await Service('POST', `commentary/${answer.id}/like`);
                if (_like.status === 201) {
                    answer.likes.push(_like.data)
                    setState({ ...state })
                }
            }
        }
    }

    const removeAsnwerLike = async (commentId, responseId) => {
        let commentary = state.commentaries.find((comment) => comment.id === commentId);
        if (commentary) {
            let answer = commentary.answers.find((answer) => answer.id === responseId);
            if (answer) {
                let _like = await Service('DELETE', `commentary/${answer.id}/like`);
                if (_like.status === 200) {
                    answer.likes = answer.likes.filter((like) => like.userId !== state.userId);
                    setState({ ...state })
                }
            }
        }
    }

    const removeLike = async (id) => {
        let commentary = state.commentaries.find((comment) => comment.id === id);
        if (commentary) {
            let _like = await Service('DELETE', `commentary/${id}/like`);
            if (_like.status === 200) {
                commentary.likes = commentary.likes.filter((like) => like.userId !== state.userId);
                setState({ ...state })
            }
        }
    }

    var months = new Array(7);
    months[0] = "Enero";
    months[1] = "Febrero";
    months[2] = "Marzo";
    months[3] = "Abril";
    months[4] = "Mayo";
    months[5] = "Junio";
    months[6] = "Julio";
    months[7] = "Agosto";
    months[8] = "Septiembre";
    months[9] = "Octubre";
    months[10] = "Noviembre";
    months[11] = "Diciembre";

    const cursoCompletado = (category, semana, tema, subtema_i) => {

        let _subtemas_to_check = state.curso[category].curso.modulos[semana].temas[tema];
        // console.log(_subtemas_to_check)
        let _contador = 0;
        state.curso[category].curso.modulos[semana].temas[tema].subtemas.forEach(subtema => {
            if (subtema.status === true) {
                _contador++
            }
        });
        if (_contador === _subtemas_to_check.subtemas.length) {
            state.curso[category].curso.modulos[semana].temas[tema].status = true;
            // console.log(state.curso[category].curso.modulos[semana].temas[tema])
            setState({ ...state })
        }

    }

    const next_subt = () => {
        if (state.sub_tema_select_index != null) {

            const _sub_temas = state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas.length - 1;
            if (state.sub_tema_select_index < _sub_temas) {
                state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
                state.sub_tema_select_index = state.sub_tema_select_index + 1
                setState({ ...state })
                console.log("brincando subtema")
                return;
            }

            if (state.sub_tema_select_index === _sub_temas) {
                state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
                state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].status = true;
                state.sub_tema_modal = false;
                setState({ ...state })
                console.log("Completo tema")
                return;
            }

            // const _temas = state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas.length - 1;
            // if (state.tema_dia < _temas) {
            //     setState({ ...state, tema_dia: (state.tema_dia + 1) });
            //     console.log("brincando tema")
            //     return;
            // }

            // if (state.tema_dia === _temas) {
            //     state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
            //     state.sub_tema_modal= false;
            //     state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].status=true;
            //     // completar corazón
            //     setState({ ...state })
            //     // console.log("cerrar modal")
            //     return;
            // }

            // const _semana = state?.curso[state.category_id]?.curso?.modulos.length - 1;
            // if (state.mostrar_semana < _semana) {
            //     setState({ ...state, mostrar_semana: (state.mostrar_semana + 1) });
            //     console.log("brincando semana")
            //     return;
            // }

            // const _curso = state?.curso.length - 1;
            // if (state.category_id < _curso) {
            //     state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
            //     state.sub_tema_select_index = 0;
            //     state.tema_dia = 0;
            //     state.mostrar_semana = 0;
            //     state.category_id = Number(state.category_id) + 1;

            //     setState({ ...state });
            //     console.log("brincando curso")
            //     return;
            // }

            // if (state.sub_tema_select_index === _sub_temas && state.category_id === _curso) {
            //     state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
            //     setState({ ...state })
            //     console.log("fin del curso")
            //     return;
            // }
        }

    }

    const back_subt = () => {
        if (state.sub_tema_select_index != null) {

            const _sub_temas = state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas.length - 1;
            if (state.sub_tema_select_index > 0) {
                state.sub_tema_select_index = state.sub_tema_select_index - 1
                setState({ ...state })
                console.log("regresando subtema")
                return;
            }

            if (state.sub_tema_select_index === 0) {
                state.sub_tema_modal = false;
                setState({ ...state })
                console.log("cerrar modal")
                return;
            }

            // const _temas = state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas.length - 1;
            // if (state.tema_dia > 0) {
            //     setState({ ...state, tema_dia: (state.tema_dia - 1) });
            //     console.log("regresando tema")
            //     return;
            // }

            // if (state.tema_dia === _temas) {
            //     state.sub_tema_modal= false;
            //     setState({ ...state })
            //     // console.log("cerrar modal")
            //     return;
            // }

            // const _semana = state?.curso[state.category_id]?.curso?.modulos.length - 1;
            // if (state.mostrar_semana > 0) {
            //     setState({ ...state, mostrar_semana: (state.mostrar_semana - 1) });
            //     console.log("regresando semana")
            //     return;
            // }

            // const _curso = state?.curso.length - 1;
            // // console.log("state?.curso->", state?.curso)
            // if (state.category_id > 0) {
            //     state.sub_tema_select_index = _sub_temas;
            //     state.tema_dia = _temas;
            //     state.mostrar_semana = _semana;
            //     state.category_id = state.category_id - 1;

            //     setState({ ...state });
            //     console.log("regresando curso")
            //     return;
            // }

            // if (state.category_id === 0) {
            //     console.log("inicio del curso")
            //     return;
            // }
        }

    }

    // console.log("some->", state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas)


    return (
        <div className='container-fluid d-flex h-100'>
            <Renderif isTrue={state.loading}>
                <Loader></Loader>
            </Renderif>
            <Renderif isTrue={state.sub_tema_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, sub_tema_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='pb-5 d-flex'>
                            <div className='d-flex-center'><MdNavigateBefore size={40} color='#00BF6B' className='cursor-pointer' onClick={() => {
                                back_subt()
                            }} /></div>
                            <div className='tip'>{ }
                                {/* <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${state.tips?.includes?.Asset.find((item) => item.sys.id === state.tip_asset)?.fields?.file?.url})` }}>
                                                    </div> */}
                                {/* MOSTRAR IMAGEN */}
                                <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.image.length > 0}>
                                    <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.image})` }}>
                                    </div>
                                </Renderif>

                                <div className='h4 text-gray my-2'>
                                    {state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.name}
                                </div>
                                <div className='text-gray text-justify f-14 render-html-content'>
                                    <div dangerouslySetInnerHTML={{ __html: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.description }}></div>
                                </div>
                                <div className='text-gray f-14'>
                                    {state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip.map((tip) => {
                                        let _tip = state.tips.items.find((item) => item.sys.id === tip)
                                        // console.log(_tip)
                                        return (
                                            <div>
                                                <div className='bold f-22 text-gray my-2'>{_tip.fields.title}</div>
                                                {_tip.fields.description.content.map((content) => {
                                                    if (content.nodeType === "paragraph") {
                                                        return (
                                                            <div className='text-justify'>
                                                                {content.content.map((element) => {
                                                                    if (element.nodeType === "hyperlink") {
                                                                        return (

                                                                            <a href={`${element.data.uri}`} target='_blank'>{element.data.uri}</a>
                                                                        )
                                                                    } else if (element.marks?.find((bold) => bold?.type === "bold")) {
                                                                        <span className='bold'>{element.value}</span>
                                                                    } else {
                                                                        return (
                                                                            <span>{element.value}</span>
                                                                        )
                                                                    }



                                                                })}
                                                            </div>
                                                        )
                                                    }
                                                    else if (content.nodeType === "unordered-list") {
                                                        return (
                                                            <ul>
                                                                {content.content.map((element) => {
                                                                    return (
                                                                        <li>
                                                                            {element.content[0].content.map((li) => {

                                                                                if (li.marks?.find((bold) => bold?.type === "bold")) {
                                                                                    return (
                                                                                        <span className='bold'>{li.value}</span>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <span>{li.value}</span>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </li>
                                                                    )


                                                                })}
                                                            </ul>
                                                        )
                                                    }
                                                    else if (content.nodeType === "blockquote") {
                                                        return (
                                                            <div>
                                                                {content.content.map((element) => {
                                                                    return (
                                                                        <div>{element.content[0].value}</div>
                                                                    )


                                                                })}
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                                <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video.length > 0}>
                                    {/* <div className='yt-video' >
                                        <iframe class="h-100 w-100" src={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video} allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen"
                                            msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen" ></iframe>
                                    </div> */}
                                    <div className='yt-video'><iframe className="h-100 w-100" src={`https://player.vimeo.com/video/${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" ></iframe></div>
                                    <script src="https://player.vimeo.com/api/player.js"></script>
                                </Renderif>

                                {/* MOSTRAR DOCUMENTO */}
                                <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento.length > 0}>
                                    <div className='my-4'>
                                        <div className='d-flex'>
                                            <div className='col'>
                                            </div>
                                            <div className='my-2'>
                                                <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.audio.length > 0}>
                                                    <FanfareButton mp3={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.audio}></FanfareButton>

                                                </Renderif>
                                            </div>
                                        </div>
                                        <div >
                                            <iframe src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento}`} height={"400px"} width={"100%"}></iframe >
                                        </div>
                                    </div>
                                </Renderif>
                                <div>
                                    <div className={`bv-cta bv-cta--success w-50 align-self-end my-2 f-18 ${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.status === false ? `` : `d-none`}`} onClick={() => {
                                        console.log(state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.status)
                                        state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
                                        setState({ ...state })
                                        cursoCompletado(state.category_id, state.mostrar_semana, state.tema_dia, state.sub_tema_select_index);
                                        update_curso();
                                    }}>
                                        Marcar Completado
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex-center'><MdNavigateNext size={40} color='#00BF6B' className='cursor-pointer' onClick={() => {

                                next_subt()
                                update_curso();
                            }} /></div>
                        </div>

                    </div>
                </div>
            </Renderif>
            <div>
                <SideMenu page={'course_inside'}></SideMenu>
            </div>

            {/* MODALES */}
            {/* MODALES */}
            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top mobile-d-none'>
                    <img className='m-3' width={120} src='/assets/images/logo.png'></img>
                </div>
                <div className='container-fluid mx-auto'>
                    <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${_curso?.featureImage})` }}>

                    </div>

                </div>
                <div className='p-1 psm-4'>
                    <div className='d-flex'>
                        <div className='mobile-d-none'>
                            <IoArrowBackCircle size={40} color='#00BF6B' className='cursor-pointer' onClick={() => {
                                navigate('/user')
                            }} />
                        </div>
                        <div className='w-100 margin-bottom'>
                            <div className='d-sm-flex'>
                                {/* MENU DE CURSOS */}
                                <Renderif isTrue={state.full_screen === false}>
                                    <div className='col-12 col-sm-6 px-sm-4 px-2'>
                                        <div className='d-flex'>
                                            <div className='d-flex-center f-34 bold text-gray '>

                                                {_curso?.title}
                                            </div>
                                            {/* <div className='col'></div> */}
                                            {/* <Renderif isTrue={state.btn_select === 2}>
                                            <div className='d-flex-center text-green text-underline f-14 cursor-pointer'>
                                                Mis Avances
                                            </div>
                                        </Renderif> */}

                                        </div>
                                        <div className='d-flex my-3 mobile-d-none '>
                                            <div className={`bv-cta f-18 ${state.btn_select === 1 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end `} onClick={() => { setState({ ...state, btn_select: 1, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                                Detalles de plan
                                            </div>
                                            <div className={`bv-cta f-18 ${state.btn_select === 2 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end mx-4`} onClick={() => { setState({ ...state, btn_select: 2 }) }} >
                                                Cursos
                                            </div>
                                            <div className={`bv-cta f-18 ${state.btn_select === 3 ? 'bv-cta--success' : 'bv-cta--unselect'} w-50 align-self-end `} onClick={() => { setState({ ...state, btn_select: 3, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                                Comunidad
                                            </div>
                                        </div>
                                        <div className='d-md-none d-flex my-3'>
                                            <div className={`bv-cta-mobile d-flex-center f-24 ${state.btn_select === 1 ? 'bv-cta--success-mobile w-100' : 'bv-cta--unselect'} align-self-end `} onClick={() => { setState({ ...state, btn_select: 1, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                                <IoInformationCircleOutline size={32} className={`${state.btn_select === 1 ? 'mx-2' : ''}`} /> {state.btn_select === 1 ? 'Detalles de plan' : ''}
                                            </div>
                                            <div className={`bv-cta-mobile d-flex-center f-24 ${state.btn_select === 2 ? 'bv-cta--success-mobile w-100' : 'bv-cta--unselect'} align-self-end mx-2`} onClick={() => { setState({ ...state, btn_select: 2 }) }} >
                                                <BsBook size={30} className={`${state.btn_select === 2 ? 'mx-2' : ''}`} /> {state.btn_select === 2 ? 'Cursos' : ''}
                                            </div>
                                            <div className={`bv-cta-mobile d-flex-center f-24 ${state.btn_select === 3 ? 'bv-cta--success-mobile w-100' : 'bv-cta--unselect'} align-self-end `} onClick={() => { setState({ ...state, btn_select: 3, mostrar_semana: 0, tema_dia: null, sub_tema_select_index: null }) }} >
                                                <IoPeopleOutline size={30} className={`${state.btn_select === 3 ? 'mx-2' : ''}`} /> {state.btn_select === 3 ? 'Comunidad' : ''}
                                            </div>
                                        </div>
                                        <Renderif isTrue={state.btn_select === 2}>
                                            <div className='bv-cta--success p-2 r-10 mobile-d-none'>
                                                <Splide options={{
                                                    perPage: 3,
                                                    focus: 0,
                                                    omitEnd: true,
                                                    updateOnMove: true,
                                                    pagination: false
                                                }} >
                                                    <div className='col'>

                                                    </div>
                                                    {state.curso.map((item, i) => {
                                                        return (
                                                            <SplideSlide>
                                                                <div className={`cursor-pointer image-container  ${state.curso.length - 1 === i ? `` : `b-right-option`}`} onClick={() => { setState({ ...state, category_id: item.id,tema_dia:null, sub_tema_select_index:null}) }}>{item.category}</div>
                                                            </SplideSlide>
                                                        )
                                                    })}
                                                    <div className='col'>

                                                    </div>
                                                </Splide>
                                            </div>
                                            <div className='d-md-none'>
                                                <div className='col'>
                                                    <select className='bv-cta--success w-100 p-2 r-10 text-center f-24 bold select-curso' onChange={(e) => { setState({ ...state, category_id: e.currentTarget.value }) }}>
                                                        {state.curso.map((item, i) => {
                                                            return (
                                                                <option className='text-gray' value={item.id} >{item.category}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </Renderif>


                                        <div className='d-flex my-3 w-100'>
                                            {/* DETALLES DE PLAN */}
                                            <Renderif isTrue={state.btn_select === 1}>
                                                <div className='detalle text-justify w-100'>
                                                    <div className='w-100 community-section' dangerouslySetInnerHTML={{ __html: _curso?.description }}></div>
                                                </div>
                                            </Renderif>

                                            {/* CURSOS */}
                                            <Renderif isTrue={state.btn_select === 2 && state.category_id !== null}>
                                                <div className='cursos'>
                                                    <div className='d-flex-center'>
                                                        <div className='me-2 text-gray f-20'>
                                                            Semana:
                                                        </div>
                                                        <div className='d-flex'>
                                                            {

                                                                // console.log(state?.curso?.find((element)=>element.id===state.category_id))?.curso
                                                                state?.curso[state.category_id]?.curso?.modulos?.map((item, i) => {
                                                                    return (
                                                                        <span className={`mx-1 dot-text text-white bold d-flex-center  ${state.mostrar_semana === i ? 'bg-gradient-green' : ''}`}
                                                                            onClick={() => { setState({ ...state, mostrar_semana: i, tema_dia: null, sub_tema_select_index: null}) }}>
                                                                            {i + 1}
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                    </div>

                                                    <div className='text-gray f-22'>
                                                        {state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.name}
                                                    </div>
                                                    {/* <div>
                                                        {
                                                            state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas.map((item, i) => {
                                                                
                                                            })
                                                        }
                                                    </div> */}
                                                    <div>
                                                        {
                                                            state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas.map((item, i) => {

                                                                let tema_status = state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana].temas[i].status
                                                                return (
                                                                    <div>
                                                                        <div className={`d-flex f-22  ${i === 0 ? 'border-bottom-gray' : 'border-bottom-gray'}`}>
                                                                            <div>
                                                                                <Renderif isTrue={state.tema_dia === i}><IoIosArrowDropupCircle size={36} color='#00BF6B' className='cursor-pointer' onClick={() => { if (state.tema_dia === i) { setState({ ...state, tema_dia: null, sub_tema_select_index: null }) } else { setState({ ...state, tema_dia: i, sub_tema_select_index: null }) } }} /></Renderif>
                                                                                <Renderif isTrue={state.tema_dia !== i}><IoIosArrowDropdownCircle size={36} color='#777777' className='cursor-pointer' onClick={() => { if (state.tema_dia === i) { setState({ ...state, tema_dia: null, sub_tema_select_index: null }) } else { setState({ ...state, tema_dia: i, sub_tema_select_index: null }) } }} /></Renderif>
                                                                            </div>
                                                                            <div className={`cursor-pointer ${state.tema_dia === i ? 'text-green' : 'text-gray'} ps-2`} onClick={() => { if (state.tema_dia === i) { setState({ ...state, tema_dia: null, sub_tema_select_index: null }) } else { setState({ ...state, tema_dia: i, sub_tema_select_index: null }) } }}><span className='bold'>Día {i + 1}</span> - {item.name}</div>
                                                                            <div className='col'></div>
                                                                            <div className='f-16 text-gray d-flex-center me-3 w-100px'> {tema_status === true ? <span>Completado</span> : <span>En curso...</span>}</div>
                                                                            <div><FaHeart size={32} color={tema_status === true ? 'red' : 'gray'} /></div>
                                                                        </div>

                                                                        <Renderif isTrue={state.tema_dia === i}>
                                                                            {
                                                                                item?.subtemas?.map((subitem, sub_i) => {
                                                                                    // console.log(subitem)
                                                                                    return (
                                                                                        <div className='ms-4 ps-4 me-2 b-bottom'>
                                                                                            <div className='d-flex'>
                                                                                                <div className={`cursor-pointer mobile-d-none ${state.sub_tema_select === 1 && state.sub_tema_select_index === sub_i ? 'text-green' : 'text-gray'}`} onClick={() => {
                                                                                                    setState({ ...state, sub_tema_select: 1, sub_tema_select_index: sub_i, tip_asset: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip[0] });
                                                                                                }}>
                                                                                                    {subitem.name}
                                                                                                </div>
                                                                                                <div className={`cursor-pointer d-sm-none ${state.sub_tema_select === 1 && state.sub_tema_select_index === sub_i ? 'text-green' : 'text-gray'}`} onClick={() => {
                                                                                                    setState({ ...state, sub_tema_modal: true, sub_tema_select: 1, sub_tema_select_index: sub_i, tip_asset: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip[0] });
                                                                                                }}>
                                                                                                    {subitem.name}
                                                                                                </div>
                                                                                                <div className='col'>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <FaCheckCircle size={18} color={subitem.status === true ? 'green' : '#777777'} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Renderif>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>


                                                </div>
                                            </Renderif>

                                            {/* COMUNIDAD */}
                                            <Renderif isTrue={state.btn_select === 3}>
                                                <div className='comunidad w-100'>
                                                    <div className='my-3 text-gray f-26 lh-sm'>
                                                        <div>
                                                            ¿Tienes una duda o pregunta?
                                                        </div>
                                                        <div>
                                                            Habla con la comunidad.
                                                        </div>
                                                    </div>
                                                    <div className='text-gray f-16'>
                                                        Haz tu pregunta. (Máximo 400 Caracteres).
                                                    </div>
                                                    <div>
                                                        <textarea id='txtarea' className='w-100' maxLength="400"></textarea>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col'></div>
                                                        <div className='bv-cta bv-cta--success w-25 align-self-end my-2 f-18' onClick={() => {
                                                            askQuestion();
                                                        }}>
                                                            Publicar Pregunta
                                                        </div>
                                                    </div>
                                                </div>
                                            </Renderif>
                                        </div>
                                    </div>
                                </Renderif>

                                {/* FIN MENU DE CURSOS */}
                                {/* <div className='border-left-gray'></div> */}
                                {/* RESULTADOS DEL MENU DE CURSOS */}
                                <div className={`${state.full_screen === true ? `col-12` : `col-sm-6 col-12`} px-4`}>
                                    <Renderif isTrue={state.btn_select === 1}>

                                    </Renderif>

                                    <Renderif isTrue={state.btn_select === 2}>

                                        <div className='cursos'>
                                            <Renderif isTrue={state.sub_tema_select === 1 && state.tema_dia !== null && state.sub_tema_select_index === null}>
                                                        <div dangerouslySetInnerHTML={{ __html: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.description }}></div>
                                            </Renderif>
                                            <Renderif isTrue={state.sub_tema_select === 1 && state.sub_tema_select_index !== null}>
                                                <div className='tip mobile-d-none'>{ }
                                                    {/* <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${state.tips?.includes?.Asset.find((item) => item.sys.id === state.tip_asset)?.fields?.file?.url})` }}>
                                                    </div> */}
                                                    {/* MOSTRAR IMAGEN */}
                                                    <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.image.length > 0}>
                                                        <div className='tip-full-aux-detail-plan' style={{ backgroundImage: ` url(${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.image})` }}>
                                                        </div>
                                                    </Renderif>

                                                    <div className='h4 text-gray my-2'>
                                                        {state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.name}
                                                    </div>
                                                    <div className='text-gray text-justify f-14 render-html-content'>
                                                        <div dangerouslySetInnerHTML={{ __html: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.description }}></div>
                                                    </div>
                                                    <div className='text-gray f-14'>
                                                        {state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.tip.map((tip) => {
                                                            let _tip = state.tips.items.find((item) => item.sys.id === tip)
                                                            // console.log(_tip)
                                                            return (
                                                                <div>
                                                                    <div className='bold f-22 text-gray my-2'>{_tip.fields.title}</div>
                                                                    {_tip.fields.description.content.map((content) => {
                                                                        if (content.nodeType === "paragraph") {
                                                                            return (
                                                                                <div className='text-justify'>
                                                                                    {content.content.map((element) => {
                                                                                        if (element.nodeType === "hyperlink") {
                                                                                            return (

                                                                                                <a href={`${element.data.uri}`} target='_blank'>{element.data.uri}</a>
                                                                                            )
                                                                                        } else if (element.marks?.find((bold) => bold?.type === "bold")) {
                                                                                            <span className='bold'>{element.value}</span>
                                                                                        } else {
                                                                                            return (
                                                                                                <span>{element.value}</span>
                                                                                            )
                                                                                        }



                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        else if (content.nodeType === "unordered-list") {
                                                                            return (
                                                                                <ul>
                                                                                    {content.content.map((element) => {
                                                                                        return (
                                                                                            <li>
                                                                                                {element.content[0].content.map((li) => {

                                                                                                    if (li.marks?.find((bold) => bold?.type === "bold")) {
                                                                                                        return (
                                                                                                            <span className='bold'>{li.value}</span>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <span>{li.value}</span>
                                                                                                        )
                                                                                                    }
                                                                                                })}
                                                                                            </li>
                                                                                        )


                                                                                    })}
                                                                                </ul>
                                                                            )
                                                                        }
                                                                        else if (content.nodeType === "blockquote") {
                                                                            return (
                                                                                <div>
                                                                                    {content.content.map((element) => {
                                                                                        return (
                                                                                            <div>{element.content[0].value}</div>
                                                                                        )


                                                                                    })}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video.length > 0}>
                                                        {/* <div className='yt-video' >
                                                            <iframe class="h-100 w-100" src={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video} allowfullscreen="allowfullscreen"
                                                                mozallowfullscreen="mozallowfullscreen"
                                                                msallowfullscreen="msallowfullscreen"
                                                                oallowfullscreen="oallowfullscreen"
                                                                webkitallowfullscreen="webkitallowfullscreen" ></iframe>
                                                        </div> */}
                                                        <div className='yt-video'><iframe className="h-100 w-100" src={`https://player.vimeo.com/video/${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" ></iframe></div>
                                                        <script src="https://player.vimeo.com/api/player.js"></script>
                                                    </Renderif>

                                                    {/* MOSTRAR DOCUMENTO */}
                                                    <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento.length > 0}>
                                                        <div className='my-4'>
                                                            <div className='d-flex'>
                                                                <div className='col'>
                                                                </div>
                                                                <div className='my-2'>
                                                                    <Renderif isTrue={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.audio.length > 0}>
                                                                        <FanfareButton mp3={state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.audio}></FanfareButton>
                                                                        {/* <PiSpeakerHighBold size={34} color='white' className='bg-gradient-green r-25 p-1 me-2 cursor-pointer' onClick={() => {

                                                                        }} /> */}

                                                                    </Renderif>

                                                                    <RxSize size={34} color='white' className='bg-gradient-green r-25 p-1 cursor-pointer' onClick={() => {
                                                                        setState({ ...state, full_screen: true });
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            {/* <div >
                                                                <embed src={`${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento}#zoom=90&navpanes=0`} type='application/pdf' height={"400px"} width={"100%"}></embed>
                                                            </div> */}
                                                            <div >
                                                                <iframe src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.documento}`} height={"400px"} width={"100%"}></iframe >
                                                            </div>
                                                        </div>
                                                    </Renderif>
                                                    {/* <div >
                                                        <iframe  src={`/Lorem_ipsum.pdf#zoom=90&navpanes=0`} type='application/pdf' height={"400px"} width={"100%"}></iframe >
                                                    </div> */}
                                                    {/* <div >
                                                        <iframe src={`https://drive.google.com/file/d/1elPXCy_xC9GAkGixqIH8MKwRnLV7ukJk/preview`} allow="autoplay" height={"400px"} width={"100%"}></iframe >
                                                    </div> */}
                                                    <div>
                                                        <div className={`bv-cta bv-cta--success w-50 align-self-end my-2 f-18 ${state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.status === false ? `` : `d-none`}`} onClick={() => {
                                                            //el corazon
                                                            // console.log(state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia])
                                                            console.log(state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.subtemas[state.sub_tema_select_index]?.status)
                                                            state.curso[state.category_id].curso.modulos[state.mostrar_semana].temas[state.tema_dia].subtemas[state.sub_tema_select_index].status = true
                                                            setState({ ...state })
                                                            cursoCompletado(state.category_id, state.mostrar_semana, state.tema_dia, state.sub_tema_select_index);
                                                            update_curso();

                                                        }}>
                                                            Marcar Completado
                                                        </div>
                                                    </div>
                                                </div>
                                            </Renderif>
                                            <Renderif isTrue={state.tema_dia !== null && state.sub_tema_select_index === null}>
                                                {/* <div className='text-gray text-justify f-14'>
                                                    <div dangerouslySetInnerHTML={{ __html: state?.curso[state.category_id]?.curso?.modulos[state.mostrar_semana]?.temas[state.tema_dia]?.description }}></div>
                                                </div> */}
                                            </Renderif>
                                        </div>

                                    </Renderif>

                                    <Renderif isTrue={state.btn_select === 3}>
                                        <div className='comunidad d-flex flex-column h-100'>
                                            <div className='comments'>
                                                {
                                                    state.commentaries?.map((item, i) => {
                                                        var like = item.likes.find((like) => like.userId === state.userId);
                                                        return (
                                                            <div className='comentario d-flex my-2'>
                                                                <div className='me-2'>
                                                                    <img src='/assets/images/user.png' className='user-image m-2'></img>
                                                                </div>
                                                                <div className='contenido f-14 text-gray'>
                                                                    <div><span className='bold f-16'>{item.user.firstName}</span> - {item.createdAt?.split('-')[2].split('T')[0]} de {months[new Date(item.createdAt).getMonth()].toLowerCase()} de {item.createdAt?.split('-')[0]}</div>
                                                                    <div>{item.content}</div>
                                                                    <div className='text-gray-30 bold'>
                                                                        <span className='me-3 cursor-pointer' onClick={() => {
                                                                            setState({ ...state, is_responding: item.id, responding_comment_userName: item.user.firstName })
                                                                        }} >Responder</span>
                                                                        <span onClick={() => {
                                                                            console.log(like)
                                                                            if (like === undefined) {
                                                                                addLike(item.id)
                                                                            } else {
                                                                                removeLike(item.id)
                                                                            }

                                                                        }} >
                                                                            <FaHeart size={20} color={` ${like ? `red` : `#BEC3C9`}`} className='me-1 cursor-pointer' />{item.likes.length}
                                                                        </span>
                                                                    </div>
                                                                    <Renderif isTrue={item.answers.length > 0}>
                                                                        <div className='bold text-gray-30 cursor-pointer' onClick={() => {
                                                                            setState({ ...state, view_answers: i })
                                                                        }} >
                                                                            Ver {item.answers.length} respuestas mas
                                                                        </div>
                                                                    </Renderif>
                                                                    <Renderif isTrue={i === state.view_answers}>
                                                                        {
                                                                            item?.answers?.map((answer, i_answer) => {
                                                                                var answer_like = answer.likes.find((like) => like.userId === state.userId)
                                                                                return (
                                                                                    <div className='answers d-flex my-2'>
                                                                                        <div className='me-2'>
                                                                                            <img src='/assets/images/user.png' className='user-image m-2'></img>
                                                                                        </div>
                                                                                        <div className='contenido f-14 text-gray'>
                                                                                            <div><span className='bold f-16'>{answer.user.firstName}</span> - {answer.createdAt?.split('-')[2].split('T')[0]} de {months[new Date(answer.createdAt).getMonth()].toLowerCase()} de {answer.createdAt?.split('-')[0]}</div>
                                                                                            <div>{answer.content}</div>
                                                                                            <div className='text-gray-30 bold'>
                                                                                                <span className='me-3 cursor-pointer' onClick={() => {
                                                                                                    setState({ ...state, is_responding: item.id, responding_comment_userName: answer.user.firstName, responding_comment_userId: item.userId })
                                                                                                }} >Responder</span>
                                                                                                <span onClick={() => {
                                                                                                    console.log(answer_like)
                                                                                                    if (answer_like === undefined) {
                                                                                                        addAsnwerLike(item.id, answer.id)
                                                                                                    } else {
                                                                                                        removeAsnwerLike(item.id, answer.id)
                                                                                                    }

                                                                                                }} >
                                                                                                    <FaHeart size={20} color={` ${answer_like ? `red` : `#BEC3C9`}`} className='me-1 cursor-pointer' />{answer.likes.length}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })}

                                                                    </Renderif>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='col'>

                                            </div>
                                            <Renderif isTrue={state.is_responding !== null}>
                                                <div className='d-flex col-12 bg-white px-3'>
                                                    <div className='me-1 user-reponse'>
                                                        @{state.responding_comment_userName}
                                                    </div>
                                                    <div className='col'>
                                                        <textarea id='txtarea_response' className='w-100 h-100' maxLength="400"></textarea>
                                                    </div>
                                                    <div className='d-flex-center'>
                                                        <IoArrowUpCircle size={40} color='#00BF6B' className='cursor-pointer' onClick={() => {
                                                            responseQuestion()
                                                        }} />
                                                    </div>
                                                </div>
                                            </Renderif>

                                        </div>
                                    </Renderif>




                                </div>
                                {/* FIN DE RESULTADOS DEL MENU DE CURSOS */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const FanfareButton = (mp3) => {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [isPause, setIsPause] = React.useState(false);
    const location = useLocation();
    const [play, { pause, stop }] = useSound(mp3.mp3, {
        onend: () => setIsPlaying(false),
    });
    useEffect(() => {
        const handleRouteChange = () => {
            stop();
        };

        // Listen for the route change event
        window.addEventListener('beforeunload', handleRouteChange);

        return () => {
            stop();
            window.removeEventListener('beforeunload', handleRouteChange);
        };
    }, [location.pathname, play, stop]);


    const handleClick = () => {
        if (isPlaying === false) {
            play();
            setIsPlaying(true);
        } else if (isPlaying === true && isPause === false) {
            pause();
            setIsPause(true);
        } else if (isPlaying === true && isPause === true) {
            play();
            setIsPause(false);
        }
    };

    return (
        // <PiSpeakerHighBold size={34} color='white' className='bg-gradient-green r-25 p-1 me-2 cursor-pointer' onClick={handleClick}
        <PiSpeakerHighBold size={34} color='white' className='bg-gradient-green r-25 p-1 me-2 cursor-pointer' onClick={handleClick}
        />
    );
};
