import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import Header from '../components/header'
import Renderif from '../components/renderif'
import { BiCheck } from 'react-icons/bi'
import BVCalendar from '../components/calendar'
import BackBtn from '../components/back-btn'
import { useNavigate, useParams } from 'react-router-dom'
import { Service } from '../services/api'
import { AlertContext } from '../context/alert_context'
import { addHour, format } from "@formkit/tempo"
import { AuthContext } from '../context/auth_context'
import { HiVideoCamera } from 'react-icons/hi2'
import { MdPeopleAlt } from 'react-icons/md'

export default function AgendarCitaView() {
    const navigate = useNavigate()
    const {especialidad} = useParams()
    const {notify} = useContext(AlertContext)
    const {user,logout} = useContext(AuthContext)


    const date = new Date();
    date.setDate(date.getDate() + 1)

    const [state, setState] = useState({
        especialistas:[],
        specialistSelected:null,
        specialist_appointments:[],
        step:0,
        selected_date:null,
        selected_day:date,
        selected_time:'',
        selected_daytime:null,
        hours:[],
        available: null,
        busy:[],
        isOnline:true
    })


    useEffect(()=>{
        const init = async () => {

            let especialistas = await Service('GET', 'specialists')
            console.log(especialistas)
            if(especialistas.status===401){
                logout();
                navigate('/')
            }
            let plans = await Service('GET', 'plans')
            if(plans.status===401){
                logout();
                navigate('/')
            }
            console.log(plans)
            console.log(especialistas)
            console.log(especialidad)
            // state.especialistas = especialistas.data
            switch (especialidad) {
                case '1':
                    console.log("AS")
                    state.especialistas = especialistas.data.filter((item)=>item.specialist?.specialty === 'NUTRITION')
                    break;
                case '2':
                    state.especialistas = especialistas.data.filter((item)=>item.specialist?.specialty === 'PSYCHOLOGY')
                    break;
                case '3':
                    state.especialistas = especialistas.data.filter((item)=>item.specialist?.specialty === 'GYNECOLOGY')
                    break;
                default:
                   state.especialistas = []
            }
            setState({...state})

        }
        init()
    },[])
    Number.prototype.pad = function(size) {
        var s = String(this);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    }
    const agendar = async () => {
        console.log(user)
        var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var day = state.selected_daytime.getDate();
        var month = state.selected_daytime.getMonth() + 1; //months are zero based
        var year = state.selected_daytime.getFullYear();
        let time = state.selected_daytime.toLocaleTimeString().split(' ')[0].replace(/(.*)\D\d+/, '$1');

        

        let _format_date_init = format(state.selected_daytime, "YYYY-MM-DDTHH:mm:ss", 'es')
        let _format_date_end =  format(addHour(_format_date_init, 1), "YYYY-MM-DDTHH:mm:ss", 'es')
        
      

      

        let date_init_parsed = year + '-' + month.pad() + '-' + day.pad() + 'T' + time + ':00';
        
        let t = time.split(':')
        t = parseInt(t[0]) + 1
        let date_end_parsed = year + '-' + month.pad() + '-' + day.pad() + 'T' + t + ':00:00';


        let body = {
            specialist:state.specialistSelected.email,
            init:_format_date_init,
            end:_format_date_end,
            tz:tz,
            isOnline:state.isOnline
        }
        console.log(body);
        // console.log(date_init_parsed);
        // console.log(date_end_parsed)

        let agendar = await Service('POST', 'appointments', body)
        if(agendar.status === 201){
            notify({title:'Agendar', message:'Listo!, Tu cita ha sido agendada.', type:'success'})
            // let body = {
            //     priceId:''
            // }
            // let _payment = await Service('POST', `plans/checkoutSession`)
            // console.log(_payment)
            // if(_payment.status === 201){

            //     window.open(`${_payment.data.url}`, '_blank')
            // }

            
            navigate(-1)
        }else if(agendar.status===401){
            logout();
            navigate('/')
        }else{
            notify({title:'Error', message:'Lo sentimos, en este momento no ha sido posible agendat tu cita.', type:'error'})
        }
        
    }

    var arr = [
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
    ]
 

    var weekday=new Array(7);
    weekday[0]="Domingo";
    weekday[1]="Lunes";
    weekday[2]="Martes";
    weekday[3]="Miercoles";
    weekday[4]="Jueves";
    weekday[5]="Viernes";
    weekday[6]="Sabado";

    var weekday_en=new Array(7);
    weekday_en[0]="Domingo";
    weekday_en[1]="Lunes";
    weekday_en[2]="Martes";
    weekday_en[3]="Miercoles";
    weekday_en[4]="Jueves";
    weekday_en[5]="Viernes";
    weekday_en[6]="Sabado";

    var months=new Array(7);
    months[0]="Enero";
    months[1]="Febrero";
    months[2]="Marzo";
    months[3]="Abril";
    months[4]="Mayo";
    months[5]="Junio";
    months[6]="Julio";
    months[7]="Agosto";
    months[8]="Septiembre";
    months[9]="Octubre";
    months[10]="Noviembre";
    months[11]="Diciembre";
  return (
    <div className='container-fluid d-flex h-100'>
        <div>
            <SideMenu page="citas"></SideMenu>
        </div>
        <div className='col'>
            <Header></Header>
            <div className='container-fluid container-xl mx-auto p-3'>
                
    
                <Renderif isTrue={state.step === 0}>

                <BackBtn></BackBtn>
                <div className='f-26 text-green bold'>Agendar Cita</div>
                <div className='f-32 bold text-gray'>Selecciona a uno de nuestros Especialistas.</div>
                <div className='f-18 bold text-green'>Elige una especialidad</div>
                <div className='my-3'></div>

              
          
                    <div className='d-flex flex-wrap'>

                       

                            {state.especialistas.map((item, i)=>{
                                return (
                                    <div className='col-12 col-lg-6 col-xl-4 pe-2'>
                                        <div className={`specialist-card mt-2 ${state.specialistSelected?.email === item.email ? 'specialist-card--active':''}`} onClick={async () => {
                                    
                                
                                        state.specialistSelected = item;
                                        // state.step = 1
                                        setState({...state})
                                    }}>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <div className='avatar avatar--xxl' style={
                                                    {backgroundImage: `${item.email === 'mborquez@mydiet.mx' ? 'url(/assets/images/Marissa_Borques.png)' : item.email === 'egonzalez@mydiet'? 'url(/assets/images/Estrella_Gonzalez.png)': 'url(/assets/images/doc.jpg)'}` }
                                                }></div>
                                            </div>
                                            <div className='d-flex flex-column px-2'>
                                                <div className='f-22 bold text-gray'>{item.firstName} {item.lastName}</div>
                                                <div className='f-14 bold text-gray'>{item.email}</div>
                                                <div className='mt-2'></div>
                                                <div className='f-18 bolder text-green'>{item.specialist.specialty}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Renderif isTrue={state.specialistSelected?.email === item.email}>
                                        <div className='d-flex'>
                                            <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                                                 state.isOnline = false
                                                 state.step = 2
                                                 setState({...state})
                                            }}>
                                                Presencial
                                            </div>
                                            <div className='mx-2'></div>
                                            <div className='bv-cta bv-cta--success mt-2 w-100 align-self-end f-22' onClick={() => {
                                                 state.isOnline = true
                                                 state.step = 2
                                                 setState({...state})
                                            }}>
                                                Online
                                            </div>

                                        </div>
                                    </Renderif>
                                    </div>
                  
                                )
                            })}

                    
                    <div style={{height:300}}></div>
                        
                  
                    
                    </div>
                </Renderif>

                <Renderif isTrue={state.step === 1}>

                <BackBtn text='Especialista' onClick={()=>{setState({...state, step:0})}}></BackBtn>
                <div className='f-26 text-green bold'>Agendar Cita</div>
                <div className='f-16 text-gray'>Selecciona tipo de cita</div>
                <div className='my-3'></div>

              
          
                    <div className='d-flex'>
                        <div className='col pe-2'>

                             <div className='specialist-card mt-2' onClick={async () => {
                                 state.isOnline = true
                                state.step = 2
                                setState({...state})
                                }}>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <div className='nu-icon nu-icon--big'>
                                            <HiVideoCamera color='gray' size={30}></HiVideoCamera>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column px-2'>
                                        <div className='f-18 bold text-gray'>Cita Online</div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className='specialist-card mt-2' onClick={async () => {
                                state.isOnline = false
                               state.step = 2
                               setState({...state})
                               }}>
                               <div className='d-flex align-items-center'>
                                   <div>
                                       <div className='nu-icon nu-icon--big'>
                                           <MdPeopleAlt color='gray' size={30}></MdPeopleAlt>
                                       </div>
                                   </div>
                                   <div className='d-flex flex-column px-2'>
                                       <div className='f-18 bold text-gray'>Cita Presencial</div>
                                      
                                   </div>
                               </div>
                           </div>
                   




                                
                        </div>
                        <div className='col pe-2'>
                            {/* <div className='specialist-card' onClick={() => {
                                    setState({...state, step:1})
                                }}>
                                    <div className='d-flex'>
                                        <div>
                                            <div className='avatar avatar--big'></div>
                                        </div>
                                        <div className='d-flex flex-column px-2'>
                                            <div className='f-18 bold text-g'>Sebastian C. Arciga Diaz</div>
                                            <div className='f-14 text-gray'>Especialidad</div>
                                            <div className='mt-2'></div>
                                            <div className='f-14 text-gray'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</div>
                                        </div>
                                    </div>
                            </div> */}
                        </div>
                        <div className='col pe-2'>
                            {/* <div className='specialist-card' onClick={() => {
                                    setState({...state, step:1})
                                }}>
                                    <div className='d-flex'>
                                        <div>
                                            <div className='avatar avatar--big'></div>
                                        </div>
                                        <div className='d-flex flex-column px-2'>
                                            <div className='f-18 bold text-g'>Sebastian C. Arciga Diaz</div>
                                            <div className='f-14 text-gray'>Especialidad</div>
                                            <div className='mt-2'></div>
                                            <div className='f-14 text-gray'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</div>
                                        </div>
                                    </div>
                            </div> */}
                        </div>
                    
                    </div>
                </Renderif>

                <Renderif isTrue={state.step === 2}>
                <BackBtn text='Tipo de cita' onClick={()=>{setState({...state, step:0})}}></BackBtn>
                <div className='f-26 text-green bold'>Agendar Cita</div>
                <div className='f-16 text-gray'>Selecciona fecha y hora.</div>

                <div className='my-3'></div>
              
                    <div className='d-flex flex-wrap justify-content-center'>


                       
                            <div className='d-flex flex-wrap w-100'>


                                <div className='specialist-card specialist-card--active'>
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <div className='avatar avatar--xxl' style={{backgroundImage: 'url(/assets/images/doc.jpg)'}}></div>
                                        </div>
                                        <div className='d-flex flex-column px-2'>
                                                <div className='f-22 bold text-gray'>{state.specialistSelected?.firstName} {state.specialistSelected?.lastName}</div>
                                                <div className='f-14 bold text-gray'>{state.specialistSelected?.email}</div>
                                                <div className='mt-2'></div>
                                                <div className='f-18 bolder text-green'>{state.specialistSelected?.specialist.specialty}</div>
                                            </div>
                                    </div>
                                </div>





                                <div className='col-12 col-lg d-flex justify-content-center justify-content-lg-end'>

                                 

                                <div className={`mt-3 mt-lg-0 bv-cta bv-cta--success`} onClick={()=> {
                                    // agendar()
                                    state.step = 0
                                    setState({...state})
                                }}>
                                    <div className='f-18'>Cambiar Especialista</div>
                                </div>

                                <div className='mx-2'></div>

                                <div className={`mt-3 mt-lg-0  bv-cta bv-cta--${state.selected_daytime !== null ? 'success' : 'disabled'}`} onClick={()=> {
                                    agendar()
                                  
                                }}>
                                    <div className='f-18'>Agendar</div>
                        
                                </div>
                                </div>

                            </div>

                            

                   

                        <div className='col-12 col-lg-12  mt-3'>
                        
                            <div className='d-flex flex-wrap overflow-hidden'>

                                <div className='col-12 col-lg-8 pe-0 pe-lg-3'>
                                <BVCalendar init={date} onChange={async(evt)=>{
                                    let body = {
                                        date: format(evt, 'MM-DD-YYYY')
                                    }
                                    console.log(state.specialistSelected)
                                    let appointments = await Service('POST', `appointments/findBySpecialist/${state.specialistSelected.id}`, body)
                                    console.log(appointments.data)
                                        
                                    
                                    let y = evt.getFullYear()
                                    let m = (evt.getMonth() + 1) < 10 ? '0'+(evt.getMonth() + 1) : (evt.getMonth() + 1)
                                    let d = evt.getDate() < 10 ? '0'+evt.getDate() : evt.getDate()

                                    console.log(y+"-"+m+"-"+d)
                                    
                                    let _busy = []

                        

                                    appointments.data?.map((appointment)=>{
                                    
                                    
                                        _busy.push(appointment.init.split('.')[0].split('T')[1])
                                        
                                    })
                                    
                                    console.log(state.specialistSelected.specialist.schedules)

                                    let day = weekday_en[evt.getDay()]
                                    console.log(evt.getDay())

                                    let _day = evt.getDay() !== 0 ? evt.getDay() : 7  

                                    let horarios = state.specialistSelected.specialist.schedules?.filter((item) => item.day === _day )[0]?.workingHours
                                    console.log(horarios)
                                    let ranges = [];
                                    
                                    if(horarios !== undefined){
                                        const chunkSize = 2;
                                        for (let i = 0; i < horarios.length; i += chunkSize) {
                                            const chunk = horarios.slice(i, i + chunkSize);
                                            
                                            let half_init = (chunk[0] % 1 === 0 ? false : true);
                                            let half_end = (chunk[1] % 1 === 0 ? false : true);
                                            let init = (chunk[0] % 1 === 0 ? chunk[0] : chunk[0] - 0.5);
                                            let end = (chunk[1] % 1 === 0 ? chunk[1] : chunk[1] - 0.5);
                                            
                                            let startTime = (init >= 10 ? init : '0'+init) +":"+ (half_init ? '30':'00') +":00";
                                            let endTime = (end >= 10 ? end : '0'+end) +":"+ (half_end ? '30':'00') +":00";
                                        

                                            // init = half ?  init >= 10 ? init : '0'+init

                                            ranges.push({
                                                "startTime": startTime,
                                                "endTime": endTime
                                            },)
                                            // do whatever
                                        }
                                    }
                                
                                    



                                    let hours = []

                                    ranges?.map((horario)=>{
                                        // console.log(horario)
                                        let start = horario.startTime;
                                        let end = horario.endTime;

                
                                        let time_start = new Date();
                                        time_start.setHours(start.split(':')[0], start.split(':')[1], start.split(':')[2])
                                    
                                        let time_end = new Date();
                                        time_end.setHours(end.split(':')[0], end.split(':')[1], end.split(':')[2])



                                        let hora = new Date();
                                        for (let i = time_start.getHours() ; i <= time_end.getHours() - 1; i++) {
                                            
                                            hora.setHours(i, start.split(':')[1], '00')
                                            let h = hora.getHours() >= 10 ? hora.getHours() : '0'+hora.getHours()
                                            let m = hora.getMinutes() >= 10 ? hora.getMinutes() : '0'+hora.getMinutes()
                                            let hm = h +':'+m;
                                            console.log(hm)
                                            console.log(_busy)
                                            if(!_busy.includes(hm+":00")){
                                                hours.push(hm)
                                            }
                                    

                                        }

                           

                        })
                  

                    
                   
                            setState({...state, selected_day:evt, hours})
                                    
                                }}></BVCalendar>
                                </div>

                                
                             

                                <div className='col-12 col-lg-4 overflow-auto hide-scroll bg-white r-10 mt-3 mt-lg-0'>

                                    <Renderif isTrue={state.selected_day < new Date()}>
                                        <div className='f-14 bold text-gray px-3 pt-3'>En MyDiet, programar citas solo es posible a partir del día siguiente.</div>
                                    </Renderif> 

                                    <Renderif isTrue={state.selected_day > new Date()}>
                                    {
                                        state.hours.map((item,i)=>{
                                           
                                            return(
                                                <div key={i} className={`px-2 cursor-pointer prevent-select d-flex align-items-center justify-content-center py-2 b-bottom  highlight-hover ${state.selected_time === i ? 'highlight-active':''}`} onClick={()=>{
                                                    let daytime = new Date(new Date(state.selected_day).setHours(item.split(':')[0], item.split(':')[1], '00'));
                                                        console.log(daytime)
                                                        
                                                        setState({...state, selected_time:i, selected_daytime:daytime})
                                                }}>
                                                    <div className='col f-16 bold text-gray text-center'>{weekday[new Date(state.selected_day).getDay()]} {new Date(state.selected_day).getDate()}</div>
                                                    <div className='col f-16 bold text-gray text-center'>{months[new Date(state.selected_day).getMonth()]} {new Date(state.selected_day).getFullYear()}</div>
                                                    <div className='col f-16 bold text-gray text-center'>{item}</div>
                                                    <div className='bv-icon bv-icon--md me-2'>
                                                        <BiCheck size={30} color={`${state.selected_time===i ? '#00bf6b':'transparent'}`}></BiCheck>
                                                    </div>
                                                    {/* <div className={`bv-pill cursor-pointer ${i === 0 ? 'bv-pill--primary':'bv-pill--gray'}`}>Seleccionar</div> */}
                                                </div>
                                            )
                                        })
                                    }
                                    </Renderif>

                                
                                </div>

                            </div>


                            {/* <div className='d-flex mt-3'>
                                <div className='col'></div>
                                <div className={`bv-cta bv-cta--${state.selected_daytime !== null ? 'success' : 'disabled'}`} onClick={()=> {
                                    agendar()
                                    //navigate(-1)
                                }}>
                                    Agendar
                        
                                </div>
                            </div> */}
                        </div>
                     
                    
                    </div>
                </Renderif>

              

            </div>
            <div style={{height:200}}></div>
        </div>

    </div>
  )
}
